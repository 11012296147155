import React from 'react'
import { colors } from '../../shared/colors';
import { Banner } from '../../shared/components/banner';
import { CallToAction } from '../../shared/components/call-to-action/call-to-action';
import { ImagePreloader } from '../../shared/helpers/image-preloader';
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Slider } from '@mui/material';
import { SamplePrice } from './screens/sample-price';
import { getVehicles } from '../../lib/services/vehicle.service';
import { filterByMake, filterByPriceRange, priceRangeLabelFormat, sortASC, sortDESC } from './helpers/helpers';

const imageUrls = [
  require('../../assets/images/my-car.jpg')
];

export const MyCar = () => {

    const [vehicles, setVehicles] = React.useState<any>(null);
    const [persistedVehicles, setPersistedVehicles] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [priceRange, setPriceRange] = React.useState<number[]>([0, 0]);
    const [makes, setMakes] = React.useState<string[]>([]);
    const [selectedMakes, setSelectedMakes] = React.useState<string[]>([]);

    const fetchAll = async (): Promise<void> => {
        const vehicles: any = await getVehicles();
        const makes: string[] = vehicles.map((vehicle: any) => vehicle.make);
        setMakes([...new Set(makes)]);
        setVehicles(vehicles.filter((vehicle: any) => vehicle.options));
        setPersistedVehicles(vehicles);
        setLoading(false);
    };

    const handleSort = (opt: string): void => {
        setLoading(true);
        if(opt === 'ASC') vehicles.sort(sortASC)
        else vehicles.sort(sortDESC);
        setLoading(false);
    };
  
    const handleAmountChange = (event: Event, range: number | number[]) => {
        let vehicles: any = [];
        if(selectedMakes.length > 0) vehicles = filterByMake(persistedVehicles, selectedMakes);
        else vehicles = persistedVehicles;
        const filteredVehicles = filterByPriceRange(vehicles, range as number[]);
        setVehicles(filteredVehicles);
        setPriceRange(range as number[]);
    };
  
    // todo: object ting for price
    const handleSelectMake = (event: SelectChangeEvent<typeof selectedMakes>) => {
      const {target: { value }} = event;
      let vehicles: any = [];
      if(priceRange[1] !== 0) vehicles = filterByPriceRange(persistedVehicles, priceRange);
      else vehicles = persistedVehicles;
      const filteredVehicles = filterByMake(vehicles, value as string[]);
      setVehicles(filteredVehicles);
      setSelectedMakes(typeof value === 'string' ? value.split(',') : value);
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        fetchAll();
    }, [])

    return (
        <ImagePreloader images={imageUrls}>
            <Banner image={require('../../assets/images/my-car.jpg')} text='Start experiencing real individuality' origin={'My Cars'} breadcrumb=' / Brand New' />
            <div className='flex flex-col md:flex-row mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='flex flex-col md:w-1/4 items-end pt-10 px-4'>
                    <div className='flex flex-col w-full gap-y-5'>
                        <h1 className='font-bold uppercase' style={{color: colors.primary}}>Filters</h1>
                        <FormControl>
                            <InputLabel>Manufacturer</InputLabel>
                            <Select
                                multiple
                                value={selectedMakes}
                                onChange={handleSelectMake}
                                input={<OutlinedInput label="Manufacturer" />}
                                renderValue={(selected) => selected.join(', ')}
                                fullWidth
                            >
                                {makes && makes.map((make: string) => (
                                    <MenuItem key={make} value={make}>
                                        <Checkbox style={{color: colors.primary}} checked={selectedMakes.indexOf(make) > -1} />
                                        <ListItemText primary={make} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box className='mt-5'>
                            <h1 className='mb-2 text-gray-600'>Price Range</h1>
                            <div className='px-2'>
                                <Slider className='text-white'
                                    value={priceRange}
                                    onChange={handleAmountChange}
                                    valueLabelDisplay="auto"
                                    step={500}
                                    min={0}
                                    max={30000}
                                    style={{color: colors.primary}}
                                    valueLabelFormat={priceRangeLabelFormat}
                                />
                            </div>
                        </Box>
                        <CallToAction />
                    </div>
                </div>
                <div className='w-full md:border-l-2 md:px-10'>
                    <SamplePrice 
                        vehicles={vehicles} 
                        loading={loading}
                        handleSort={handleSort}
                    />
                </div>
            </div>
        </ImagePreloader>
    );
};
