export type OffersType = {
    target: string;
    image: string;
    link: string;
    title: string;
    text: string;
};

export const offers: OffersType[] = [
    {
        target: '_blank',
        image: require('../../../assets/images/home2.jpg'),
        link: 'https://www.avis.co.za/long-term-car-hire/why-buy-when-you-can-rent',
        title: '1 - 3 months',
        text: 'Long Term Car Hire'
    },
    {
        target: '_blank',
        image: require('../../../assets/images/home3.jpg'),
        link: 'https://www.avis.co.za/long-term-car-hire/three-to-eleven-month-car-hire',
        title: '3 - 11 months',
        text: 'Mini Lease'
    },
    {
        target: '_parent',
        image: require('../../../assets/images/home4.jpg'),
        link: 'about-ilease',
        title: '12 - 48 months',
        text: 'About iLease'
    },
]