import React from 'react'
import { SquareBullet } from '../components/square-bullet';
import { ImagePreloader } from '../../../shared/helpers/image-preloader';

const imagesUrls = [
    require('../../../assets/images/about-ilease-vehicle-options-1.jpg'),
    require('../../../assets/images/about-ilease-vehicle-options-2.jpg'),
    require('../../../assets/images/about-ilease-vehicle-options-3.jpg'),
    require('../../../assets/images/about-ilease-vehicle-options-4.jpg'),
];

export const VehicleOptions = (): React.JSX.Element => {
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
    });
    
    return (
        <ImagePreloader images={imagesUrls}>
            <h1 className='text-red-600 font-bold uppercase'>YOUR VEHICLE. YOUR OPTIONS. YOU CHOOSE:</h1>
            <div className='grid grid-cols-1 md:grid-cols-4 gap-4 text-gray-500 text-sm my-5'>
                <div className='flex flex-col'>
                    <div className='h-52 w-full'>
                        <img src={require('../../../assets/images/about-ilease-vehicle-options-1.jpg')} alt='img' className='w-full h-full object-cover' />
                    </div>
                    <p>
                        BRAND NEW FROM AVIS
                        <br />
                        Tell us what car you want. We’ll find it, buy it and lease to you. 
                    </p>
                </div>
                <div className='flex flex-col'>
                    <div className='h-52 w-full'>
                        <img src={require('../../../assets/images/about-ilease-vehicle-options-2.jpg')} alt='img' className='w-full h-full object-cover' />
                    </div>
                    <p>
                        BRAND NEW FROM OUR NETWORK
                        <br />
                        You identify and locate the car you want. We’ll deal with the franchise dealer, buy it and lease to you. 
                    </p>
                </div>
                <div className='flex flex-col'>
                    <div className='h-52 w-full'>
                        <img src={require('../../../assets/images/about-ilease-vehicle-options-3.jpg')} alt='img' className='w-full h-full object-cover' />
                    </div>
                    <p>
                        NEARLY-NEW FROM OUR NETWORK
                        <br />
                        You identify and locate the demo or &lt;1year old car you want. We’ll deal with the franchise 
                        dealer, buy it and lease to you. 
                    </p>
                </div>
                <div className='flex flex-col'>
                    <div className='h-52 w-full'>
                        <img src={require('../../../assets/images/about-ilease-vehicle-options-4.jpg')} alt='img' className='w-full h-full object-cover' />
                    </div>
                    <p>
                        NEARLY-NEW FROM AVIS
                        <br />
                        We have a range of &lt;1-year old cars available for you to choose from.
                        <br />
                        <a href='https://aviscarsales.co.za/' target='_blank' rel="noreferrer" className='text-red-500 hover:underline'>Click here</a> to view our catalogue.
                    </p>
                </div>
            </div>
            <div className='h-0.5 w-full bg-gray-200'></div>
            <div className='my-10'>
                <h1 className='text-red-600 font-bold uppercase'>Choose your terms for the lease contract</h1>
                <SquareBullet text='All quotations will include 12, 24, 36 and 48 month lease options.' />
                <SquareBullet text='Contracts will be tailored to your expected kilometre usage.' />
            </div>
        </ImagePreloader>
    );
};
